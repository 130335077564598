body{
    background-image: linear-gradient(359deg, rgb(189 183 183 / 30%), rgb(207 206 206 /20%), rgb(255 255 255));
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover;
}

.navbar{
    margin: 0!important;
    padding: 0!important;

}

.nav{
    /* background-image: linear-gradient(359deg,  rgb(189 183 183 / 30%), rgb(207 206 206 /20%), rgb(255 255 255)); */
    background-position: bottom;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size:cover;
    /* background-color: rgba(56, 56, 56, 0.3); */
    /* background-image: linear-gradient(359deg,  rgb(189 183 183 / 30%), rgb(207 206 206 /20%), rgb(255 255 255)); */
    opacity: 1;
}

.loader-container {
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
    height: 100vh; /* Optional: Adjusts height to the viewport height */
}

.nav-item{
    /* color: #363b61; */
    color: white;
}
.remove-decoreation{
    text-decoration: none;
}
.nav-item:hover{
    color: #ef0010 ;
    /* text-decoration: underline; */
    font-weight: 500;
    cursor: pointer;
}

.Nav-container{
    position: absolute;
}

.Nav-container.fixed{
    /* background-color: white; */
    position: fixed;
    transition: .5s ease-in-out;
}
.nav.fixed{
    background-position: bottom;
    background-repeat: no-repeat;
    background-size:cover;
    background: #0a3d5ba8;
    /* background-image: linear-gradient(359deg,  rgb(189 183 183 / 30%), rgb(207 206 206 /20%), rgb(255 255 255)); */

}
/* .nav-item.fixed{
    color: black;
} */

.main-logo{
  width: 20%;  
}


/* About */
.Headers{
    color: #0a3d5b;
    font-weight: 900;
    font-family: 'Lobster Two', cursive;
}
.paragraph{
    
    font-family: 'Cabin', sans-serif;
    font-size: large;
    text-align: justify;
}
.about-img{
    width: 110%;
}
/* About */

/* media coverage */

.cursor-pointer img{
    cursor: pointer;
    -webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}

.cursor-pointer:hover img{
    border-radius: 2%;
    border-color: white;
    border-width: 10px;
    z-index: 1;
    -webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.media-container > span {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2em;
  }
  
  .media-container > span > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0 2px 16px var(--shadow);
  }
  
  .media-container {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    grid-auto-rows: 120px;
    grid-auto-flow: dense;
  }
  
  .horizontal {
    grid-column: span 2;
  }
  
  .vertical {
    grid-row: span 2;
  }
  
  .big {
    grid-column: span 2;
    grid-row: span 2;
  }

  .small {
    grid-column: span 2;
    grid-row: span 1;
  }

  .media:hover, .media:hover span { 
	visibility:visible;
	top:0;
	left:100px; 
	z-index:1;
}

/* media coverage */

/* Background */

.back-paragraph{
    font-family: 'Cabin', sans-serif;
    font-size: 16px;
    text-align: justify;
}

/* Background */


/* Footer */

.footer-image{
    width: 15%;
}

.footer-font{
    font-family: 'Cabin', sans-serif;
    font-size: 100%;
    font-weight: bold;
    color: #0a3d5b;
}

.footer{
    background-image: linear-gradient(129deg, rgb(189 183 183 / 50%), rgb(255 255 255));
    /* background-attachment: local; */
    background-position: bottom;
    background-repeat: no-repeat;
    /* background-size: contain; */
    background-size: cover;
}

/* Footer */

/* Video Showcase */


.video-image{
    width: 100%;
    border-radius: 10px;
}

.video-container {
    position: relative;
}

.play-btn {
    width: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease; /* Add transition for a smooth effect */
}

.video-link:hover .play-btn {
    opacity: 1; /* Make the play button visible on hover */
}

.video{
    border-radius: 10px;
    /* margin-top: -10px;
    margin-right: -10px; */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.video:hover{
    border-radius: 10px;
    margin-top: -10px;
    margin-right: -10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Video Showcase */

.fixed-bg{
    background-image: url("../src/Components/img/jar.jpg");
  /* min-height: 500px; */
  /* width: 100%; */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Documents */

.doc-image{
    border-radius: 10px;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/* Documents */

/* Charity */

.Charity{
    width:23%;
}

/* PDF */

.PDF-header{
    color: #0a3d5b;
    /* font-weight: 900; */
    /* font-family: 'Lobster Two', cursive; */
}

/* PDF */

/* tournament */
.tbl-header{
    color: white;
    background-color: #0a3d5b;
    font-size: 15px;
}
/* tournament */



@media (max-width:576px) {
    .main-logo{
        width: 20%;  
    }
    .Nav-container{
        position: relative;
    }
    .nav{
        background: #0a3d5ba8;
        /* background-image: linear-gradient(359deg,  rgb(189 183 183 / 30%), rgb(207 206 206 /20%), rgb(255 255 255)); */
    }
    .doc-image{
        width: 100%;
    }
    .footer-image{
        width: 55%;
        height: 10%;
    }
    .Charity{
        width:70%;
    }
    
}
@media (min-width:577px) and (max-width:768px) {
    .main-logo{
        width: 10%;  
    }
    /* .Nav-container{
        position:relative;
    } */ 
}
@media (min-width:769px) and (max-width:992px) {
    .main-logo{
        width: 7%;  
    }
}
@media (min-width:993px) and (max-width:1200px) {
    .main-logo{
        width: 15%;  
    }
    .nav-tog-btn{
        display: none;
    }
}
@media (min-width:1201px)  {
    .main-logo{
        width: 12%;  
    }
    .nav-tog-btn{
        display: none;
    }
    
}

